:root {
  --dark-color: #161616;
  --light-color: #f5f5f5;
  --grey-color: #444;
}

.App {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--dark-color);
  color: var(--light-color);
}

h1 {
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-bottom: 20px;
}

h1 span {
  margin-left: 10px;
}

h1 span[role="img"] {
  font-size: 28px;
}

h2 {
  font-size: 18px;
  text-align: left !important;
  width: 800px;
  max-width: 90vw;
  margin: 0 auto;
  margin-top: 30px;
  color: var(--light-color);
}

input[type="file"] {
  margin-bottom: 10px;
}

button {
  margin: 10px auto;
  padding: 0 20px;
  width: 200px;
  max-width: 90vw;
  height: 50px;
  background-color: #007bff;
  color: var(--light-color);
  border: .1em solid #007bff;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  vertical-align: middle;
  position: relative !important;
}

.red-btn {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.red-btn:hover {
  background-color: #c82333 !important;
  border-color: #bd2130 !important;
}

button progress {
  position: absolute;
  z-index: 1;
  background-color: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 5px;
}

button p {
  display: block !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 50px;
  line-height: 50px;
  padding: 0 !important;
  margin: 0 !important;
  text-align: center;
  z-index: 2;
}

button:hover {
  background-color: #0056b3;
}


.App .paragraph {
  display: none;
  font-size: 14px;
  margin: 20px auto;
  padding: 10px;
  width: 800px;
  max-width: 90vw;
  text-align: justify;
  background-color: var(--grey-color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.drop-zone {
  border: 2px dashed #ccc;
  padding: 20px;
  margin: 10px auto;
  text-align: center;
  cursor: pointer;
}

.drop-zone:hover {
  border-color: #007bff;
}

input[type="file"] {
  margin-bottom: 10px;
}

i {
  margin-left: 4px !important;
}

button:disabled {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  cursor: not-allowed;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-item {
  margin: 10px;
  padding: 0 20px;
  width: 200px;
  max-width: 90vw;
  height: 50px;
  background-color: var(--dark-color);
  color: var(--light-color);
  border: .1em solid #007bff;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  vertical-align: middle;
  position: relative !important;
}

.flex-item:hover {
  background-color: var(--grey-color);
}

p b {
  user-select: all;
}

.blue-btn {
  background-color: #007bff !important;
  border-color: #007bff !important;
}